import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SectionAboutus, SectionACT, SectionClients, SectionContact, SectionDisclaimer, SectionFooter, SectionIntro, SectionNewsArticle, SectionNewsLatest, SectionNewsOverview, SectionProfilesOverview, SectionPrivacy, SectionSlideshow, SectionTermsconditions, SectionVacancy, SectionVacancyExternal, SectionVacancyHighlight, SectionVacancyInternal, SectionVacancyZZP, SectionWorkatInternal1, SectionWorkatInternal2, SectionWorkatZZP } from './Section';

import { ElementDebugResponsive } from '../components/Element';

function PageAbout(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionAboutus
                    ip_address={props.ip_address}
                    translations={props.translations} />
                <SectionContact
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>
            
            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageClients(props)
{
    // Automatic scrolling
    const [loaded1, loaded1_set] = useState(false);
    const [loaded2, loaded2_set] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        if (props.debug === true)
            console.log('Scroll check');

        if (loaded1 === true && loaded2 === true)
        {
            if (props.debug === true)
                console.log('Scroll match');

            if (location['search'] === '?scroll=detacheren')
            {
                let element = document.getElementById('detacheren');
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY - 120
                });

                if (props.debug === true)
                    console.log('Scroll detacheren');
            }

            if (location['search'] === '?scroll=werving-en-selectie')
            {
                let element = document.getElementById('werving-en-selectie');
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY - 120
                });

                if (props.debug === true)
                    console.log('Scroll werving-en-selectie');
            }
        }
        
    }, [props.debug, loaded1, loaded2, location]);

    return (
        <React.Fragment>
            <div className="body-content">
                <SectionClients
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    upload_images={props.upload_images}
                    function_loaded1_set={loaded1_set}
                    function_loaded2_set={loaded2_set} />
                <SectionContact
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageDisclaimer(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionDisclaimer
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageHomepage(props)
{
    /*
    <SectionCareermatch
        ip_address={props.ip_address}
        translations={props.translations} />
    */
    
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionSlideshow
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} 
                    upload_images={props.upload_images}/>
                <SectionIntro
                    ip_address={props.ip_address}
                    translations={props.translations} />
                <SectionACT
                    ip_address={props.ip_address}
                    translations={props.translations} />
                <SectionNewsLatest
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    upload_images={props.upload_images} />
                <SectionVacancyHighlight
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} 
                    upload_images={props.upload_images}/>
                <SectionContact
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageNewsArticle(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionNewsArticle
                    debug={props.debug}
                    ip_address={props.ip_address}
                    upload_images={props.upload_images}
                    translations={props.translations} />
            </div>
            
            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageNewsOverview(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionNewsOverview
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    upload_images={props.upload_images} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PagePrivacy(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionPrivacy
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageProfiles(props)
{
    // Automatic scrolling
    const [loaded1, loaded1_set] = useState(false);
    const [loaded2, loaded2_set] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (props.debug === true)
            console.log('Scroll check');

        if (loaded1 === true && loaded2 === true)
        {
            if (props.debug === true)
                console.log('Scroll match');

            if (location['search'] === '?scroll=consultants')
            {
                let element = document.getElementById('consultants');
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY - 60
                });
                
                if (props.debug === true)
                    console.log('Scroll consultants');
            }
        }
        
    }, [props.debug, loaded1, loaded2, location]);

    return (
        <React.Fragment>
            <div className="body-content">
                <SectionProfilesOverview
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    upload_images={props.upload_images}
                    function_loaded1_set={loaded1_set}
                    function_loaded2_set={loaded2_set} />
            </div>

            <SectionFooter
                translations={props.translations} />
 
            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageTermsconditions(props)
{
    return (
        <React.Fragment>
            <div className="body-content">
                <SectionTermsconditions
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} />
            </div>

            <SectionFooter
                translations={props.translations} />
 
            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageVacancy(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionVacancy
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} 
                    upload_images={props.upload_images} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageVacancyOverview(props)
{
   return (
        <React.Fragment>
            <div className="body-content">
                <SectionVacancyExternal
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} 
                    upload_images={props.upload_images}/>
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageWorkat(props)
{
    // Automatic scrolling
    const [loaded1, loaded1_set] = useState(false);
    const [loaded2, loaded2_set] = useState(false);
    const [loaded3, loaded3_set] = useState(false);
    const [loaded4, loaded4_set] = useState(false);
    const [loaded5, loaded5_set] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        if (props.debug === true)
            console.log('Scroll check');

        if (loaded1 === true && loaded2 === true && loaded3 === true && loaded4 === true && loaded5 === true)
        {
            if (props.debug === true)
                console.log('Scroll match');

            if (location['search'] === '?scroll=zzp')
            {
                let element = document.getElementById('zzp');
                window.scrollTo({
                    top: element.getBoundingClientRect().top + window.scrollY - 120
                });

                if (props.debug === true)
                    console.log('Scroll zzp');
            }
        }
        
    }, [props.debug, loaded1, loaded2, loaded3, loaded4, loaded5, location]);

    return (
        <React.Fragment>
            <div className="body-content">
                <SectionWorkatInternal1
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    function_loaded1_set={loaded1_set} />
                <SectionVacancyInternal
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} 
                    upload_images={props.upload_images}
                    function_loaded2_set={loaded2_set} />
                <SectionWorkatInternal2
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    function_loaded3_set={loaded3_set} />
                <SectionWorkatZZP
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations}
                    function_loaded4_set={loaded4_set} />
                <SectionVacancyZZP
                    debug={props.debug}
                    ip_address={props.ip_address}
                    translations={props.translations} 
                    upload_images={props.upload_images}
                    function_loaded5_set={loaded5_set} />
            </div>

            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

export {
    PageAbout, PageClients, PageDisclaimer, PageHomepage, PageNewsArticle, PageNewsOverview, PagePrivacy, PageProfiles, PageTermsconditions, PageVacancy, PageVacancyOverview, PageWorkat
};